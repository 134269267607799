<template>
	<div class="box"><iframe ref="iframe" :src="path" :frameborder="0" @load="resetHeight"></iframe></div>
</template>

<script>
export default {
	props: ['name'],
	data() {
		return {
			path: '',
      isResetHight:false
		};
	},
	watch: {
		$route: {
			handler(val,oldval) {
				this.$global.navList_for_headerOne[0].children.forEach(item => {
					if (item.name == val.params.name) {
						this.path = `static/html/templates/${item.html}/${item.html}.html`
						console.log("this.path: ",this.path);
					} else if(item.children && item.children.length) {
						item.children.forEach(childrenitem => {
							if (childrenitem.name == val.params.name) {
								if (childrenitem.name == '小额免密免签' || childrenitem.name == '银联线上收银台' || childrenitem.name == '趣味科普') {
									this.path = `static/html2/${childrenitem.html}.html`
								} else {
									this.path = `static/html/templates/${childrenitem.html}/${childrenitem.html}.html`
									console.log("this.path: ",this.path);
								}
							}
						} )
					}
				})
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		resetHeight() {
			var iframe = this.$refs.iframe;
			// var _wh = document.documentElement.clientHeight - 130; //浏览器中间数据展示区域高度
			var h = 0;
			try {
				var a = iframe.contentWindow.document.body.scrollHeight;
				// var b = iframe.contentWindow.document.documentElement.scrollHeight;
				h = a;
				// h += 50; //buf
			} catch (e) {}
			iframe.style.height = h + 'px';
      console.log('resetHeight',iframe.style.height)

		}
	}
};
</script>

<style lang="scss" scoped>
.box {
	width: 100%;
	height: 100%;

	iframe {
		width: 100%;
		//height: calc(100vh - 62px);
		background: #ffffff;
	}
}
</style>
